.sherlock-container {
  text-align: center;
  width: 80%;
  max-width: 900px;
  margin: auto;
}

.finished-container {
  margin: auto;
  padding: 20px;
}

.search-form {
  margin: 0 auto;
  width: 50%;
}
