.colorizer-content {
  text-align: center;
  width: 70%;
  margin: auto;
}

.upload-form {
  width: 500px;
  text-align: center;
  margin: auto;
}

.finished-container {
  margin: auto;
  padding: 20px;
}
