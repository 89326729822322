.footer-container {
  position: absolute;
  bottom: 0;
  background-color: #212529;
  color: #f8f9fa;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  height: 5rem;
}
