.home-container {
  text-align: center;
  width: 80%;
  max-width: 900px;
  margin: auto;
  padding-top: 30px;
}

/* .cards-container {
  width: 650px;
  display: flex;
  margin: auto;
} */

.single-card {
  margin: 10px;
}
